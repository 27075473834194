import React from "react";
const Terms = () => {
  return (
    <div className="container-xs">
      <h1>Terms</h1>
      <h2>Registering Domain Name</h2>
      <p>
        To register your interest in purchasing a domain, please email{" "}
        <a href="mailto:info@dritec.co.uk">info@dritec.co.uk</a> with details of
        the domain you would like to purchase. We will then be in contact with
        details on how to get this setup.
      </p>
      <h2>Domain Name Redemption</h2>
      <ol>
        <li>
          Important information on all top level domains including,
          .com/.net/.org/.info/.biz domains etc, except for .uk and .eu/.gov.uk
          domains. Domains which are not renewed before the expiry date will
          immediately enter an expired phase period of 30 or 40 days, depending
          on the Registrar. During the Expired phase period, customers will have
          to pay a move-back fee of £2.99 (plus VAT) as well as the renewal fee
          to renew the Domain. After the expired phase period, which can usually
          last between 30 and 40 days depending on the registrar, if the Domain
          is still not renewed, it will enter a redemption period for another 30
          days. During the Redemption period, customers will have to pay a £180
          charge (plus VAT), this includes one year domain registration. If the
          Domain name is not renewed after the end of the redemption period, it
          will be available again to the public for registration. 2 year
          registration of a .com/.net/.org/.info/.biz domain is charged at
          £34.00
          <li>Important information on all .uk Domains</li>
          <ul>
            <li>
              Domains which are not renewed before the expiry date will
              immediately enter a 90 days expired phase period. During the
              expired phase period, customers will have to pay a move-back fee
              of £2.99 (plus VAT) as well as the renewal fee to renew the
              Domain.
            </li>
            <li>
              After the 90 days expired phase period, NOMINET make the Domain
              available again to the public for registration.
            </li>

            <li>2 year registration of a .co.uk domain is charged at £15.00</li>
            <li>
              2 year registration of a .gov.uk domain is charged at £112.80
            </li>
            <li>
              By purchasing a .uk domain from dritec you are agreeing to the
              terms and conditions outlined by NOMINET. A full list of terms and
              conditions can be found{" "}
              <a
                href="https://www.nominet.uk/uk-domains/policies/"
                target="_blank"
              >
                here
              </a>
            </li>
          </ul>
        </li>
      </ol>
      <h2>Domain Renewal Policy</h2>
      <ol>
        <li>
          dritec will automatically renew your domains, providing these have:
          <ul>
            <li>
              You have requested automatic renewal for the relevant domain.
            </li>
            <li>You have valid payment details stored in your account</li>
          </ul>
        </li>
        <li>
          Domains will by default renew for the same period that they were
          originally registered for. This will not apply to situations in which
          you have chosen to renew your domain via your control panel for a new
          or extended term.
        </li>
        <li>
          In order to avoid the expiry of any domain, dritec shall take payment
          for renewals up to 7 days in advance of the renewal date.
        </li>
        <li>
          dritec shall send an email, to confirm payments made on the day these
          occur.
        </li>
        <li>
          You acknowledge that dritec have the discretion to vary the renewal
          rates from time to time without prior direct notice to you.
        </li>
        <li>
          dritec accepts no liability for the loss of registration of any domain
          that has failed to be renewed due to an invalid email address provided
          to us or where a renewal reminder notification has been labelled as
          spam or junk late payments received or technical faults which are
          beyond our control.
        </li>
        <li>
          In the event that a domain is not renewed by its expiry date it shall
          cease to operate and shall be deemed to have been “expired”.
        </li>
        <li>
          dritec will issue a renewal reminder 30 days in advance of domain
          expiring.
        </li>
      </ol>
      <h2>Early Termination / Domain Transfer</h2>
      <p>
        Customer acknowledges that the amount of the fee for Services is based
        on Customer's agreement to pay the fee for the entire Initial Term, or
        Renewal Term, as applicable. There is no additional charge to transfer a
        domain to another registrar if all term payments are up to date.
      </p>
      <h2>Report domain name abuse</h2>
      <p>
        If you have a concern about a domain name registered with dritec, you
        can report certain types of abuse to us via email{" "}
        <a href="mailto:info@dritec.co.uk">info@dritec.co.uk</a>
      </p>
      <h2>Complaints Procedure</h2>
      <p>
        The dritec complaints procedure has three stages, which will be answered
        in no longer than 3 days, detailed below. We aim to resolve the majority
        of complaints at stage one, but if you remain unhappy you may take your
        complaint to the second or third stage.
      </p>
      <h3>Stage 1</h3>
      <p>
        Please contact dritec by emailing{" "}
        <a href="mailto:info@dritec.co.uk">info@dritec.co.uk</a>
      </p>
      <p>Once you have outlined the problem, we will:</p>
      <ol>
        <li>
          Try to deal with the complaint on the spot where it is straightforward
          and easily resolved. We will also ensure that we investigate what led
          to the complaint to see whether there are any actions we can take to
          avoid a similar problem arising again.
        </li>
        <li>
          For more complex complaints, we will carry out a thorough
          investigation and where we have made a mistake, we will try to put it
          right. We will advise you of any conclusion that will be used to
          improve our service.
        </li>
        <li>
          Where we have to seek further information on a complaint we will
          advise you when a response can be expected, if for any reason the
          complaint can not be addressed within the given time period.
        </li>
        <li>
          Where the complaint is about something outside our control, we will
          advise how the issue can best be taken up and with whom if we can.
        </li>
      </ol>
      <h3>Stage 2</h3>
      <ol>
        <li>
          If you are unhappy with the response you have received at stage one,
          you can go to the next stage of the complaints procedure and refer
          your complaint to the relevant senior manager. In order to ensure that
          your complaint is now dealt with at stage two please clearly mark it
          as a "Stage Two Complaint".
        </li>
        <li>
          A senior manager will then investigate your complaint and aim to give
          you a response within 20 working days.
        </li>
      </ol>
      <h3>Stage 3</h3>
      <ol>
        <li>
          If you are still not happy with the response you have received, you
          can ask for your complaint to be dealt with by the dritec Management
          Team and CEO. Your complaint will be heard at the first available time
          and we will aim to give you a response within 20 working days. Again,
          please ensure that you highlight this as a "Stage Three Complaint".
        </li>
      </ol>
      <h2>Contact Details</h2>
      <p>
        dritec can be contacted on the following methods. We aim to repond to
        any communication within 2 working days
      </p>
      <h3>Email</h3>
      <a href="mailto:info@dritec.co.uk">info@dritec.co.uk</a>
      <h3>Telephone</h3>
      <a href="tel:07595025467">07595025467</a>
      <h3>Postal</h3>
      dritec, 42 Curtis Way, Kesgrave, Suffolk, IP5 2FX
    </div>
  );
};

export default Terms;
