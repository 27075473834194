import React from "react";
import { Parallax } from "react-parallax";
function ParallaxSection() {
  return (
    <Parallax
      blur={0}
      bgImage="sheep.jpg"
      bgImageAlt="Stand out from the crowd"
      strength={1200}
      renderLayer={(percentage) => (
        <div
          style={{
            position: "absolute",
            background: `rgba(255, 125, 0, ${percentage * 1})`,
            left: "50%",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(-50%,-50%)",
            width: percentage * 500,
            height: percentage * 500,
          }}
        ></div>
      )}
    >
      <div
        style={{
          height: "1200px",
        }}
      >
        <div
          style={{
            padding: 20,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <h1>Get ready to stand out</h1>
          <h5>
            <i>dritec will&nbsp;allow you to make a lasting impression</i>
          </h5>
        </div>
      </div>
    </Parallax>
  );
}
export default ParallaxSection;
